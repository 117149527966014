/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: #50504f;
  border-bottom-right-radius: 30px;
  border: 3px none #636060;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "MyWebFont", Fallback, sans-serif;
  min-height: 100vh;
  background-size: center !important;
  width: 100%;
  /* background: linear-gradient(-120deg, rgb(9, 5, 14) 0%, #1a102b 100%); */
  background-color: black;
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  align-items: center;
  justify-content: center;
}

@font-face {
  font-family: "MyWebFont";
  src: url("./assets/font/FreakyStory.otf");
  /* IE9 Compat Modes */
}

iframe {
  display: none !important;
}

@-webkit-keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

/* Customize Antd Modal*/
.lightModal .ant-modal-content {
  border-radius: 10px !important;
}

.darkModal .ant-modal-content {
  background-color: #1e3851 !important;
  border-radius: 10px !important;
}

.staking_content {
  border-color: white !important;
}

.stake_content {
  min-height: 400px;
}

.border-custom {
  border-color: white !important;
}

.bg-custom-blur {
  backdrop-filter: blur(5px) !important;
}

.staking-grid {
  min-height: 350px;
  max-height: 700px;
}

.min-h-300 {
  min-height: 250px;
}

/*------------------------------------------------------------------*/
/*	02) Preloader
/*------------------------------------------------------------------*/
.metaverse_fn_preloader {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(15, 5, 20);
  transition: all 0.5s ease;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}
